import ContainerEcommerce from '../../../Componant/Dashboard/Ecommerce/Ecommerce'

const EcommerceDashborad = () => {
  return (
    <div className="page-body">
      <ContainerEcommerce />
    </div>
  )
}

export default EcommerceDashborad