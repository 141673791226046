import EditProfileContainer from '../../../../Componant/Application/Users/EditProfile/EditProfile'

const EditProfile = () => {
  return (
    <div className='page-body'>
      <EditProfileContainer />
    </div>
  )
}

export default EditProfile