// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardHeader, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from 'reactstrap';
// import { Check, X, Info, ArrowRight } from 'lucide-react';

// const PlanCard = ({ plan, onSelect, onViewDetails }: any) => (
//   <Card className="mb-4 h-100">
//     <CardHeader>
//       <h3>{plan.name}</h3>
//       <h4>${plan.price}/month</h4>
//     </CardHeader>
//     <CardBody>
//       <ul className="list-unstyled">
//         {plan.features.map((feature: any, index: any) => (
//           <li key={index} className="mb-2">
//             {feature.included ? <Check className="text-success" /> : <X className="text-danger" />}
//             {' '}{feature.name}
//           </li>
//         ))}
//       </ul>
//       <Button color="primary" onClick={() => onSelect(plan)} className="mt-3 w-100">Select Plan</Button>
//       <Button color="link" onClick={() => onViewDetails(plan)} className="mt-2 w-100">View Details</Button>
//     </CardBody>
//   </Card>
// );

// const SubscriptionPlansComponent_ = () => {
//   const [activeTab, setActiveTab] = useState<any>('all');
//   const [plans, setPlans] = useState<any>([]);
//   const [selectedPlan, setSelectedPlan] = useState<any>(null);
//   const [modalOpen, setModalOpen] = useState<any>(false);
//   const [comparisonModalOpen, setComparisonModalOpen] = useState<any>(false);

//   useEffect(() => {
//     fetchPlans();
//   }, []);

//   const fetchPlans = () => {
//     // Simulated API call
//     const mockPlans = [
//       {
//         id: 1,
//         game: 'Cricket',
//         name: 'Basic',
//         price: 29.99,
//         features: [
//           { name: 'Live Scores', included: true },
//           { name: 'Match Statistics', included: true },
//           { name: 'Player Profiles', included: true },
//           { name: 'Historical Data', included: false },
//           { name: 'API Access', included: false },
//         ],
//         apiCallLimit: 1000,
//         dataUpdateFrequency: '5 minutes',
//         supportLevel: 'Email',
//       },
//       {
//         id: 2,
//         game: 'Cricket',
//         name: 'Pro',
//         price: 99.99,
//         features: [
//           { name: 'Live Scores', included: true },
//           { name: 'Match Statistics', included: true },
//           { name: 'Player Profiles', included: true },
//           { name: 'Historical Data', included: true },
//           { name: 'API Access', included: true },
//         ],
//         apiCallLimit: 10000,
//         dataUpdateFrequency: '1 minute',
//         supportLevel: '24/7 Phone & Email',
//       },
//       {
//         id: 3,
//         game: 'Football',
//         name: 'Basic',
//         price: 39.99,
//         features: [
//           { name: 'Live Scores', included: true },
//           { name: 'Match Statistics', included: true },
//           { name: 'Player Profiles', included: true },
//           { name: 'Historical Data', included: false },
//           { name: 'API Access', included: false },
//         ],
//         apiCallLimit: 1500,
//         dataUpdateFrequency: '5 minutes',
//         supportLevel: 'Email',
//       },
//       // Add more plans for different games
//     ];
//     setPlans(mockPlans);
//   };

//   const handleSelectPlan = (plan: any) => {
//     setSelectedPlan(plan);
//     setModalOpen(true);
//   };

//   const handleViewDetails = (plan: any) => {
//     setSelectedPlan(plan);
//     setModalOpen(true);
//   };

//   const confirmPlanSelection = () => {
//     // Implement plan selection logic here
//     console.log('Selected plan:', selectedPlan);
//     setModalOpen(false);
//     // You might want to redirect to a checkout page or show a confirmation message
//   };

//   // const uniqueGames = ['All', ...new Set(plans.map(plan => plan.game))];
//   const uniqueGames: any = ['All', ...Array.from(new Set(plans.map((plan: { game: string }) => plan.game)))];

//   return (
//     <div className='page-body'>
//     <Container fluid className="p-4">
//       {/* <h1 className="mb-4">Subscription Plans</h1> */}

//       <Nav tabs className="mb-4">
//         {uniqueGames.map((game: any) => (
//           <NavItem key={game}>
//             <NavLink
//               className={activeTab === (game.toLowerCase() === 'all' ? 'all' : game) ? 'active' : ''}
//               onClick={() => setActiveTab(game.toLowerCase() === 'all' ? 'all' : game)}
//             >
//               {game}
//             </NavLink>
//           </NavItem>
//         ))}
//       </Nav>

//       <Button color="info" className="mb-4" onClick={() => setComparisonModalOpen(true)}>
//         Compare All Plans
//       </Button>

//       <TabContent activeTab={activeTab}>
//         {uniqueGames.map((game: any) => (
//           <TabPane key={game} tabId={game.toLowerCase() === 'all' ? 'all' : game}>
//             <Row>
//               {plans
//                 .filter((plan: any) => activeTab === 'all' || plan.game === game)
//                 .map((plan: any) => (
//                   <Col key={plan.id} md={4}>
//                     <PlanCard 
//                       plan={plan} 
//                       onSelect={handleSelectPlan} 
//                       onViewDetails={handleViewDetails}
//                     />
//                   </Col>
//                 ))
//               }
//             </Row>
//           </TabPane>
//         ))}
//       </TabContent>

//       <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg">
//         <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
//           {selectedPlan?.name} Plan Details
//         </ModalHeader>
//         <ModalBody>
//           {selectedPlan && (
//             <>
//               <h3>{selectedPlan.game} - {selectedPlan.name} Plan</h3>
//               <p><strong>Price:</strong> ${selectedPlan.price}/month</p>
//               <h4>Features:</h4>
//               <ul>
//                 {selectedPlan?.features?.map((feature: any, index: any) => (
//                   <li key={index}>
//                     {feature.included ? <Check className="text-success" /> : <X className="text-danger" />}
//                     {' '}{feature.name}
//                   </li>
//                 ))}
//               </ul>
//               <p><strong>API Call Limit:</strong> {selectedPlan.apiCallLimit} calls/day</p>
//               <p><strong>Data Update Frequency:</strong> {selectedPlan.dataUpdateFrequency}</p>
//               <p><strong>Support Level:</strong> {selectedPlan.supportLevel}</p>
//             </>
//           )}
//         </ModalBody>
//         <ModalFooter>
//           <Button color="primary" onClick={confirmPlanSelection}>Confirm Selection</Button>
//           <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
//         </ModalFooter>
//       </Modal>

//       <Modal isOpen={comparisonModalOpen} toggle={() => setComparisonModalOpen(!comparisonModalOpen)} size="xl">
//         <ModalHeader toggle={() => setComparisonModalOpen(!comparisonModalOpen)}>
//           Plan Comparison
//         </ModalHeader>
//         <ModalBody>
//           <Table responsive>
//             <thead>
//               <tr>
//                 <th>Feature</th>
//                 {plans.map((plan: any) => (
//                   <th key={plan.id}>{plan.game} - {plan.name}</th>
//                 ))}
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <th>Price (Monthly)</th>
//                 {plans.map((plan: any) => (
//                   <td key={plan.id}>${plan.price}</td>
//                 ))}
//               </tr>
//               {plans[0]?.features.map((feature: any, index: any) => (
//                 <tr key={index}>
//                   <th>{feature.name}</th>
//                   {plans.map((plan: any) => (
//                     <td key={plan.id}>
//                       {plan.features[index].included ? <Check className="text-success" /> : <X className="text-danger" />}
//                     </td>
//                   ))}
//                 </tr>
//               ))}
//               <tr>
//                 <th>API Call Limit</th>
//                 {plans.map((plan: any) => (
//                   <td key={plan.id}>{plan.apiCallLimit} calls/day</td>
//                 ))}
//               </tr>
//               <tr>
//                 <th>Data Update Frequency</th>
//                 {plans.map((plan: any) => (
//                   <td key={plan.id}>{plan.dataUpdateFrequency}</td>
//                 ))}
//               </tr>
//               <tr>
//                 <th>Support Level</th>
//                 {plans.map((plan: any) => (
//                   <td key={plan.id}>{plan.supportLevel}</td>
//                 ))}
//               </tr>
//             </tbody>
//           </Table>
//         </ModalBody>
//       </Modal>
//     </Container>
//     </div>
//   );
// };

// export default SubscriptionPlansComponent_;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardHeader, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Badge, ListGroup, ListGroupItem } from 'reactstrap';
import { Check, X, Info, ArrowRight } from 'lucide-react';
import { AppDispatch, RootState } from '../../ReduxToolkit/Store';
import { createToken, getSubscribe, sessionIdData } from '../../ReduxToolkit/Reducers/Change/Subscribe';
import ContentLoaderBecomeMember from '../../Componant/Application/Ecommerce/Pricing/ContentLoaderBecomeMember';
import TopLeftToast from '../../Componant/BonusUi/Toast/LiveToast/TopLeftToast/TopLeftToast';
import { cashfree } from '../../cashfree/util';
import { useLocation } from 'react-router-dom';

const PlanCard = ({ plan, onSelect, onViewDetails }: any) => (
  <Card className="mb-4 h-100" >
    <CardHeader>
      <h3>{plan.name}</h3>
      <h4>₹{plan.amount}</h4>
    </CardHeader>
    <CardBody>
      <ul className="list-unstyled">
        <li className="mb-2">
          <Check className="text-success" /> Access Matches: {plan.feature.access_matches}
        </li>
        <li className="mb-2">
          <Check className="text-success" /> API Calls: {plan.feature.api_calls}
        </li>
        <li className="mb-2">
          <Check className="text-success" /> Access Apis: {plan.feature.access_apis}
        </li>
        <li className="mb-2">
          <Check className="text-success" /> Level: {plan.feature.level}
        </li>
        <li className="mb-2">
          <Check className="text-success" /> Support: {plan.feature.support}
        </li>
      </ul>
      <Button color="primary" onClick={() => onSelect(plan)} className="mt-3 w-100">Select Plan</Button>
      <Button color="link" onClick={() => onViewDetails(plan)} className="mt-2 w-100">View Details</Button>
    </CardBody>
  </Card>
);

const SubscriptionPlansComponent_ = () => {
  const [activeTab, setActiveTab] = useState<string>('all');
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [comparisonModalOpen, setComparisonModalOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [txt, setTxt] = useState<string>("");
  const [planType, setPlanType] = useState("");
  const location = useLocation();
  let checkStatus = location.pathname.split("/")[3]?location.pathname.split("/")[3]:"";
  const [status, setStatus] = useState(checkStatus);
  // cashfree
  const [sessionId, setSessionId] = useState("");

  const dispatch = useDispatch<AppDispatch>();
  let version = cashfree.version();
  console.log("statusstatusstatus", status);
  // const getSessionId = (e: any) => {
  //   e.preventDefault();
  //   try {
  //     dispatch(sessionIdData(version)).unwrap();
      
  //   } catch (error) {
  //     console.error("Error fetching live matches:", error);
  //   }
  // }

  // const handleRedirect = () => {
   
  // }
  
  useEffect(() => {
    if(sessionId !== ""){
      console.log("sessionId", sessionId);
      console.log("REACT_APP_BACKEND_URL", process.env.REACT_APP_BACKEND_URL);
      
      let checkoutOptions = {
        paymentSessionId: sessionId,
        returnUrl: `${process.env.REACT_APP_BACKEND_URL}/api/v1/order/status/order/{order_id}`,
      }
      cashfree.checkout(checkoutOptions).then(function(result: any){
        if(result.error){
          alert(result.error.message);
        }
        if(result.redirect){
          console.log("redirecting");
          console.log("result", result);
        }
      });
    }
  }, [sessionId]);

  const plansData = useSelector((state: RootState) => state.subscribe.plans);
  const storedUser = localStorage.getItem("login-user");
  const { user } = JSON.parse(storedUser || '{}');

  useEffect(() => {
    dispatch(getSubscribe());
  }, [dispatch]);

  const handleSelectPlan = (plan: any) => {
    setSelectedPlan(plan);
    setModalOpen(true);
  };

  const handleViewDetails = (plan: any) => {
    setSelectedPlan(plan);
    setModalOpen(true);
  };

  const confirmPlanSelection = async () => {
    if (selectedPlan) {
      try {
        if(planType == ""){
          console.log("select plan type");
          setTxt(`Please select plan type`);
          setShowToast(true);
          return;
        }
        const data = {
          year_or_month: planType,
          id: selectedPlan.id,
        };
        const res = await dispatch(createToken(data)).unwrap();
        if (res?.message) {
          console.log("res from create token", res);
          window.location.href = res.url;
          setSessionId(res?.response?.sessionId)
          // handleRedirect();
          setTxt(`${user.name} Token Created Successfully`);
          setShowToast(true);
        } else {
          setTxt(`${res.payload}`);
          setShowToast(true);
        }
      } catch (error: any) {
        console.log("error in subscription", error);
        setTxt(`${user.name} ${error}`);
        setShowToast(true);
      }
    }
    setModalOpen(false);
  };

  const uniqueGames = plansData?.data?.data ? ['All', ...Array.from(new Set(plansData.data.map((plan: any) => plan.game)))] : ['All'];

  return (
    <div className='page-body'>
      <Container fluid className="p-4">
        {/* <Nav tabs className="mb-4">
          {uniqueGames.map((game: any) => (
            <NavItem key={game}>
              <NavLink
                className={activeTab === (game.toLowerCase() === 'all' ? 'all' : game) ? 'active' : ''}
                onClick={() => setActiveTab(game.toLowerCase() === 'all' ? 'all' : game)}
              >
                {game}
              </NavLink>
            </NavItem>
          ))}
        </Nav> */}

        <Button color="info" className="mb-4" onClick={() => setComparisonModalOpen(true)}>
          Compare All Plans
        </Button>

        <TabContent activeTab={activeTab}>
          {uniqueGames.map((game: any) => (
            <TabPane key={game} tabId={game.toLowerCase() === 'all' ? 'all' : game}>
              <Row>
                {plansData?.isLoading ? (
                  Array(3).fill(0).map((_, index) => (
                    <Col key={index} md={4}>
                      <ContentLoaderBecomeMember />
                    </Col>
                  ))
                ) : (
                  plansData?.data
                    ?.filter((plan: any) => activeTab === 'all' || plan.game === game)
                    .map((plan: any) => (
                      <Col className='mb-2' key={plan.id} md={4}>
                        <PlanCard 
                          plan={plan} 
                          onSelect={handleSelectPlan} 
                          onViewDetails={handleViewDetails}
                        />
                      </Col>
                    ))
                )}
              </Row>
            </TabPane>
          ))}
        </TabContent>

        {/* <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg">
          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
            {selectedPlan?.name} Plan Details
          </ModalHeader>
          <ModalBody>
            {selectedPlan && (
              <>
                <h3>{selectedPlan.game} - {selectedPlan.name} Plan</h3>
                <p style={{lineHeight: '2px', marginTop: '14px'}}><strong>Monthly Price:</strong> ₹{selectedPlan.amount}</p>
                <p style={{lineHeight: '2px'}}><strong>Yearly Price:</strong> ₹{selectedPlan.yearly_amount}</p>
                <h4>Features:</h4>
                <ul>
                  <li>Access Matches: <strong>{selectedPlan.feature.access_matches}</strong></li>
                  <li>API Calls: <strong>{selectedPlan.feature.api_calls}</strong></li>
                  <li>Access Apis: <strong>{selectedPlan.feature.access_apis}</strong></li>
                  <li>Level: <strong>{selectedPlan.feature.level}</strong></li>
                  <li>Support: <strong>{selectedPlan.feature.support}</strong></li>
                </ul>
                <div className="radio-inputs">
                  <span>Plan: &nbsp;&nbsp;&nbsp;</span>
                  <input onChange={(e) => setPlanType(e.target.value)} value="month" type="radio" name="year_or_month" />
                  <span>Monthly</span>
                  <span className='mx-2'></span>
                  <input onChange={(e) => setPlanType(e.target.value)} value="year" type="radio" name="year_or_month" />
                  <span>Yearly</span>
                </div>
                <p className='text-danger'>Before choosing plan select plan type</p>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={confirmPlanSelection}>Confirm Selection</Button>
            <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
          </ModalFooter>
        </Modal> */}
        <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg">
      <ModalHeader toggle={() => setModalOpen(!modalOpen)} className="bg-danger text-white">
        <span className='text-white'>{selectedPlan?.name} Plan Details</span>
      </ModalHeader>
      <ModalBody>
        {selectedPlan && (
          <Card className="border-0 shadow-sm">
            <CardBody>
              <h3 className="text-center mb-4">{selectedPlan.game} - {selectedPlan.name} Plan</h3>
              
              <div className="d-flex justify-content-around mb-4">
                <div className="text-center">
                  <h5>Monthly Price</h5>
                  <h3 className="text-primary">₹{selectedPlan.amount}</h3>
                </div>
                <div className="text-center">
                  <h5>Yearly Price</h5>
                  <h3 className="text-success">₹{selectedPlan.yearly_amount}</h3>
                </div>
              </div>

              <h4 className="mb-3">Features:</h4>
              <ListGroup flush>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <span>Access Matches</span>
                  <strong>{selectedPlan.feature.access_matches}</strong>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <span>API Calls</span>
                  <strong>{selectedPlan.feature.api_calls}</strong>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <span>Access APIs</span>
                  <strong>{selectedPlan.feature.access_apis}</strong>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <span>Level</span>
                  <strong>{selectedPlan.feature.level}</strong>
                </ListGroupItem>
                <ListGroupItem className="d-flex justify-content-between align-items-center">
                  <span>Support</span>
                  <strong>{selectedPlan.feature.support}</strong>
                </ListGroupItem>
              </ListGroup>

              <div className="mt-4">
                <h5 className="mb-3">Select Plan Type:</h5>
                <div className="d-flex justify-content-center">
                  <div className="form-check form-check-inline">
                    <input
                      value="month" type="radio" name="year_or_month"
                      onChange={(e) => setPlanType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="monthlyPlan">
                      Monthly
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      value="year" type="radio" name="year_or_month"
                      onChange={(e) => setPlanType(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="yearlyPlan">
                      Yearly
                    </label>
                  </div>
                </div>
              </div>
              
              <p className="text-danger mt-2 text-center">
                <small>Please select a plan type before confirming your selection.</small>
              </p>
            </CardBody>
          </Card>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={confirmPlanSelection}>
          <Check size={18} className="me-2" />
          Confirm Selection
        </Button>
        <Button color="dark" onClick={() => setModalOpen(!modalOpen)}>Cancel</Button>
      </ModalFooter>
    </Modal>

        <Modal isOpen={comparisonModalOpen} toggle={() => setComparisonModalOpen(!comparisonModalOpen)} size="xl">
          <ModalHeader toggle={() => setComparisonModalOpen(!comparisonModalOpen)}>
            Plan Comparison
          </ModalHeader>
          <ModalBody>
            <Table responsive>
              <thead>
                <tr>
                  <th>Feature</th>
                  {plansData?.data?.map((plan: any) => (
                    <th key={plan.id}>Cricket - {plan.name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Monthly Price</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>₹{plan.amount}</td>
                  ))}
                </tr>
                <tr>
                  <th>Yearly Price</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>{plan.yearly_amount}</td>
                  ))}
                </tr>
                <tr>
                  <th>Access Matches</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>{plan.feature.access_matches}</td>
                  ))}
                </tr>
                <tr>
                  <th>API Calls</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>{plan.feature.api_calls}</td>
                  ))}
                </tr>
                <tr>
                  <th>Access APIs</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>{plan.feature.access_apis}</td>
                  ))}
                </tr>
                <tr>
                  <th>Level</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>{plan.feature.level}</td>
                  ))}
                </tr>
                <tr>
                  <th>Support</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}>{plan.feature.support}</td>
                  ))}
                </tr>
                {/* <tr>
                  <th>Maintenance</th>
                  {plansData?.data?.map((plan: any) => (
                    <td key={plan.id}><Check className="text-success" /></td>
                  ))}
                </tr> */}
              </tbody>
            </Table>
          </ModalBody>
        </Modal>

        {showToast && <TopLeftToast txt={txt} open={showToast} setOpenToast={setShowToast} />}
        {status !== "" && <TopLeftToast txt={status === "success" ?"Payment Success":"Payment Failed"} open={showToast} setOpenToast={setShowToast} />}
      </Container>
    </div>
  );
};

export default SubscriptionPlansComponent_;