import LoginWithImageTwoContainer from '../../../../Componant/OtherPages/Authentication/LoginWithImageTwo/LoginWithImageTwo'

const LoginWithImageTwo = () => {
  return (
    <div className='page-body'>
      <LoginWithImageTwoContainer />
    </div>
  )
}

export default LoginWithImageTwo