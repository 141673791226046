import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../ReduxToolkit/Reducers/Change/AuthSlice';
import { useNavigate } from 'react-router-dom';
import { FRONTEND_MAIN_URL } from '../Utils';

const LogOut = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClick = (name:string)=>{
        if(name === "Log Out"){
            dispatch(logout());
            localStorage.removeItem("login")
            localStorage.removeItem("login-user")
            window.location.href = `${FRONTEND_MAIN_URL}/login`;
        }
    }
    useEffect(() => {
        handleClick("Log Out");
    })
  return (
    <></>
  )
}

export default LogOut
