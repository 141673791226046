import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { Line } from 'react-chartjs-2'
import { Copy, RefreshCw, Users, Share2, Calendar } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../ReduxToolkit/Store'
import { getRefferer } from '../../ReduxToolkit/Reducers/Change/AuthSlice'
import { getCommission } from '../../ReduxToolkit/Reducers/Change/Subscribe'
import { FaMoneyBillWave, FaRupeeSign } from 'react-icons/fa'
import { convertToIST, FRONTEND_MAIN_URL, FRONTEND_URL } from '../../Utils'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

interface CommissionData {
  created_at: string
  commission: number
  referred_id: string
}

export default function ReferralProgramComponent() {
  const [referralModalOpen, setReferralModalOpen] = useState(false)
  const [copiedAlert, setCopiedAlert] = useState(false)
  const [copieLink, setCopyLink] = useState("")
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false)
  const [withdrawalStatus, setWithdrawalStatus] = useState('idle') // 'idle', 'pending', 'approved'
  const commissionData = useSelector((state: RootState) => state.subscribe.commission)
  const commissionEarn = useSelector((state: RootState) => state.subscribe.commission?.data)
  const reffererData = useSelector((state: RootState) => state.auth.refferer)
  const usersData = useSelector((state: RootState) => state.auth.users)
  const dispatch = useDispatch<AppDispatch>()
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [kycStatus, setKycStatus] = useState('pending') // 'pending', 'approved'


  useEffect(() => {
    dispatch(getRefferer())
    dispatch(getCommission())
  }, [dispatch])

  const userData = typeof window !== 'undefined' ? localStorage.getItem("login-user") : null
  const parsedUserData = userData ? JSON.parse(userData) : null

  const copyReferralCode = (code: string) => {
    navigator.clipboard.writeText(code)
    setCopiedAlert(true)
    setTimeout(() => setCopiedAlert(false), 2000)
  }

  const generateReferralLink = (code: string) => {
    return `${FRONTEND_MAIN_URL}/register/${code}`
  }

  const handleLinkCopied = (link: string) => {
    navigator.clipboard.writeText(generateReferralLink(link))
    setCopyLink("Link copied successfully")
    setTimeout(() => {
      setCopyLink("")
    }, 2000)
  }

  // // Process commission data for the chart
  // const processCommissionData = () => {
  //   if (!commissionEarn?.data) return { labels: [], datasets: [] }

  //   const aggregatedData = commissionEarn.data.reduce((acc: { [key: string]: number }, item: CommissionData) => {
  //     const date = convertToIST(item.created_at).split(',')[0]
  //     acc[date] = (acc[date] || 0) + item.commission
  //     return acc
  //   }, {})

  //   const sortedDates = Object.keys(aggregatedData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
  //   const amounts = sortedDates.map(date => aggregatedData[date])

  //   return {
  //     labels: sortedDates,
  //     datasets: [{
  //       label: 'Earnings',
  //       data: amounts,
  //       fill: false,
  //       borderColor: 'rgb(75, 192, 192)',
  //       tension: 0.1
  //     }]
  //   }
  // }
  const processCommissionData = () => {
    if (!commissionEarn?.data) return { labels: [], datasets: [] }

    let filteredData = commissionEarn.data

    if (startDate && endDate) {
      filteredData = filteredData.filter((item: CommissionData) => {
        const itemDate: any = new Date(item.created_at)
        return itemDate >= startDate && itemDate <= endDate
      })
    }

    const aggregatedData = filteredData.reduce((acc: { [key: string]: number }, item: CommissionData) => {
      const date = convertToIST(item.created_at).split(',')[0]
      acc[date] = (acc[date] || 0) + item.commission
      return acc
    }, {})

    const sortedDates = Object.keys(aggregatedData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    const amounts = sortedDates.map(date => aggregatedData[date])

    return {
      labels: sortedDates,
      datasets: [{
        label: 'Earnings',
        data: amounts,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    }
  }


  const earningChartData = processCommissionData()

  const handleWithdrawalRequest = () => {
    if (kycStatus === 'approved') {
      setWithdrawalStatus('pending')
      // Here you would typically make an API call to request the withdrawal
      // For now, we'll just simulate it with a timeout
      setTimeout(() => {
        setWithdrawalStatus('approved')
      }, 5000) // Simulate approval after 5 seconds
    } else {
      setWithdrawalModalOpen(true)
    }
  }


  return (
    <div className='page-body'>
      <Container fluid className="p-4">
        <p>Track and find all the details about our referral program, your stats and revenues.</p>

        <Row className="mb-4">
          <Col md={3}>
            <Card>
              <CardBody>
                <h5><FaRupeeSign size={20} className="me-2" />Earning</h5>
                <h2><FaRupeeSign size={20} className="me-2" />{commissionData.data?.totalCommission || 0}</h2>
                <Button color="primary" size="sm" onClick={() => setReferralModalOpen(true)}>View Details</Button>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardBody>
                <h5><Users size={20} className="me-2" />References</h5>
                <h2>{reffererData.data?.length || 0}</h2>
                <Button color="info" size="sm" onClick={() => {
                  dispatch(getRefferer())
                  dispatch(getCommission())
                }}><RefreshCw size={16} /></Button>
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <CardBody>
                <h5><Share2 size={20} className="me-2" />Referral Code</h5>
                <h2>{parsedUserData?.user?.reffer_code}</h2>
                <Button color="success" size="sm" onClick={() => copyReferralCode(parsedUserData?.user?.reffer_code)}>Copy Code</Button>
                {copiedAlert && <Alert color="success" className="mt-2">Copied!</Alert>}
              </CardBody>
            </Card>
          </Col>
          <Col md={3}>
            <Card color={kycStatus === 'approved' ? 'light' : 'danger'}>
              <CardBody>
                <h5><FaMoneyBillWave size={20} className="me-2" />Withdrawal</h5>
                <h2>
                  {withdrawalStatus === 'idle' && 'Ready'}
                  {withdrawalStatus === 'pending' && 'Processing'}
                  {withdrawalStatus === 'approved' && 'Approved'}
                </h2>
                <Button 
                  color={withdrawalStatus === 'pending' ? 'warning' : 'primary'} 
                  size="sm" 
                  onClick={handleWithdrawalRequest}
                  disabled={withdrawalStatus === 'pending'}
                >
                  {withdrawalStatus === 'idle' && 'Request Payout'}
                  {withdrawalStatus === 'pending' && 'Processing...'}
                  {withdrawalStatus === 'approved' && 'Approved'}
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Card className="mb-4">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>Earnings Over Time</h3>
              <div className="d-flex">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start Date"
                  className="form-control me-2"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  placeholderText="End Date"
                  className="form-control me-2"
                />
                <Button color="primary" onClick={() => {
                  setStartDate(null)
                  setEndDate(null)
                }}>
                  Reset
                </Button>
              </div>
            </div>
            {earningChartData.labels.length > 0 ? (
              <Line height={50} data={earningChartData} />
            ) : (
              <p>No earning data available for the selected date range</p>
            )}
          </CardBody>
        </Card>

        <h2 className="mb-3">Top Referred Users</h2>
        <Table responsive striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Referred</th>
              <th>Commission Earned ($)</th>
              <th>Commission (%)</th>
              <th>Registered At</th>
            </tr>
          </thead>
          <tbody>
            {reffererData?.data?.map((row: any, index: number) => (
              <tr key={index}>
                <td>{`PSA00${index+1}`}</td>
                <td>{usersData?.data?.find((user: any) => user.id === row?.referred_id)?.email}</td>
                <td>{commissionEarn?.data?.find((user: any) => user.referred_id === row.referred_id)?.commission || 0}</td>
                <td>{commissionEarn?.data?.find((user: any) => user.referred_id === row.referred_id) ? "5" : 0}%</td>
                <td>{convertToIST((row.created_at)).split(",")[0]}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal isOpen={referralModalOpen} toggle={() => setReferralModalOpen(!referralModalOpen)}>
          <ModalHeader toggle={() => setReferralModalOpen(!referralModalOpen)}>
            Referral Details
          </ModalHeader>
          <ModalBody>
            <h4>Your Referral Link</h4>
            <Input value={generateReferralLink(parsedUserData?.user?.reffer_code)} readOnly />
            <Button color="primary" className="mt-2" onClick={() => handleLinkCopied(parsedUserData?.user?.reffer_code)}>
              Copy Link
            </Button>
            {copieLink !== "" && <Alert color="success" className="mt-2">{copieLink}</Alert>}
            <h4 className="mt-4">How It Works</h4>
            <ol>
              <li>Share your unique referral link with friends or colleagues</li>
              <li>When they sign up using your link, they get a 10% discount on their first month</li>
              <li>You earn a 5% commission on their subscription for the first year</li>
            </ol>
            <Alert color="info">
              The more people you refer, the more you can earn!
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setReferralModalOpen(false)}>Close</Button>
          </ModalFooter>
        </Modal>
      </Container>
    </div>
  )
}