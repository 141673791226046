import { Card, CardBody, Container, Table } from "reactstrap";
import InvoiceTwoHeader from "./InvoiceTwoHeader";
import InvoiceTwoDetails from "./InvoiceTwoDetails";
import InvoiceTwoBilling from "./InvoiceTwoBilling";
import InvoiceTwoContent from "./InvoiceTwoContent";
import InvoiceTwoTotal from "./InvoiceTwoTotal";
import { InvoiceButtons } from "../Common/InvoiceButtons";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../ReduxToolkit/Store";
import { getLoggedUserProfile } from "../../../../../ReduxToolkit/Reducers/Change/ProfileSlice";
import ProfessionalInvoice from "./ProfessionalInvoice";

const InvoiceTwoContainer = ({ data, from }: any) => {
  console.log("ddata", data);

  const componentRef = useRef<HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const dispatch = useDispatch<AppDispatch>();

  const fetchUser = () => {
    dispatch(getLoggedUserProfile());
  };

  useEffect(() => {
    fetchUser();
  }, []);
  return (
    <>
      <div ref={componentRef}>
        <Container className="invoice-2">
          <Card className="pb-0">
            <CardBody>
              <Table
                className="table-wrapper table-responsive theme-scrollbar"
                borderless
              >
                <tbody>
                  <tr style={{padding: '0'}}>
                    <InvoiceTwoHeader />
                  </tr>
                  <tr style={{padding: '0'}}>
                    <InvoiceTwoDetails data={data} />
                  </tr>
                  <tr style={{padding: '0'}}>
                    <InvoiceTwoBilling data={data} from={from} />
                  </tr>
                  <tr style={{padding: '0'}}>
                    <InvoiceTwoContent data={data} />
                  </tr>
                  <tr style={{padding: '0'}}>
                    <InvoiceTwoTotal data={data} />
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: "20px",
                        backgroundColor: "#f2f2f2",
                        textAlign: "center",
                      }}
                    >
                      <p>Thank you for your business!</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: "10px",
                        borderTop: "1px solid #ddd",
                        fontSize: "12px",
                        color: "#666",
                      }}
                    >
                      <p style={{ margin: 0, textAlign: "center" }}>
                        <strong>Disclaimer:</strong> This is a system-generated
                        invoice. It is valid without a physical signature.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
        {/* <div ref={componentRef}>
          <Container className="invoice-2">
            <Card>
              <CardBody>
                <ProfessionalInvoice data={data} from={from} />
              </CardBody>
            </Card>
          </Container>
        </div> */}
      </div>
    </>
  );
};

export default InvoiceTwoContainer;
