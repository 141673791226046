import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';

const MatchDashboard = () => {
  const [activeTab, setActiveTab] = useState('overview');
  
  const matchInfo = {
    date: "2008-05-03",
    venue: "M Chinnaswamy Stadium",
    result: "Royal Challengers Bangalore won by 3 runs",
    playerOfMatch: "P Kumar",
    tossWinner: "Deccan Chargers",
    tossDecision: "field"
  };

  const battingData = {
    rcb: [
      { name: "B Chipli", runs: 10, balls: 7, fours: 2, sixes: 0, strikeRate: 142.86 },
      { name: "W Jaffer", runs: 44, balls: 37, fours: 6, sixes: 0, strikeRate: 118.92 },
      { name: "V Kohli", runs: 38, balls: 31, fours: 1, sixes: 2, strikeRate: 122.58 },
      { name: "JH Kallis", runs: 6, balls: 5, fours: 1, sixes: 0, strikeRate: 120.00 },
      { name: "R Dravid", runs: 26, balls: 19, fours: 1, sixes: 2, strikeRate: 136.84 },
      { name: "MV Boucher", runs: 16, balls: 14, fours: 2, sixes: 0, strikeRate: 114.29 }
    ],
    dc: [
      { name: "AC Gilchrist", runs: 10, balls: 12, fours: 2, sixes: 0, strikeRate: 83.33 },
      { name: "VVS Laxman", runs: 52, balls: 44, fours: 7, sixes: 0, strikeRate: 118.18 },
      { name: "RG Sharma", runs: 57, balls: 43, fours: 5, sixes: 3, strikeRate: 132.56 },
      { name: "SB Bangar", runs: 17, balls: 7, fours: 0, sixes: 2, strikeRate: 242.86 }
    ]
  };

  const scoringProgressionData = Array(20).fill().map((_, i) => ({
    over: i + 1,
    RCB: [2,13,22,32,39,46,51,57,71,80,85,93,103,110,114,122,126,130,143,156][i],
    DC: [7,11,17,26,31,33,43,54,57,63,75,90,97,105,112,115,128,132,137,153][i],
    target: 157
  }));

  return (
    <div className='page-body'>
    <div className="w-full bg-gray-50 p-4">
      {/* Match Header */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-4">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">IPL 2008: RCB vs DC</h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="p-4 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">Date: {matchInfo.date}</p>
            <p className="text-sm text-gray-600">Venue: {matchInfo.venue}</p>
          </div>
          <div className="p-4 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">Toss: {matchInfo.tossWinner} elected to {matchInfo.tossDecision}</p>
            <p className="text-sm text-gray-600">Player of Match: {matchInfo.playerOfMatch}</p>
          </div>
          <div className="p-4 bg-blue-50 rounded">
            <p className="text-sm font-semibold text-blue-800">{matchInfo.result}</p>
          </div>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="bg-white rounded-lg shadow-lg p-4 mb-4">
        <div className="flex space-x-4 border-b">
          <button 
            className={`px-4 py-2 ${activeTab === 'overview' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600'}`}
            onClick={() => setActiveTab('overview')}
          >
            Overview
          </button>
          <button 
            className={`px-4 py-2 ${activeTab === 'batting' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600'}`}
            onClick={() => setActiveTab('batting')}
          >
            Batting Analysis
          </button>
          <button 
            className={`px-4 py-2 ${activeTab === 'progression' ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-600'}`}
            onClick={() => setActiveTab('progression')}
          >
            Match Progression
          </button>
        </div>

        {/* Content Sections */}
        <div className="mt-4">
          {activeTab === 'overview' && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="p-4 bg-gray-50 rounded">
                <h3 className="text-lg font-semibold mb-3">RCB Innings: 156/8</h3>
                <div className="space-y-2">
                  <p className="text-sm">Run Rate: 7.67</p>
                  <p className="text-sm">Highest Scorer: W Jaffer (44 runs)</p>
                  <p className="text-sm">Most Sixes: V Kohli (2)</p>
                </div>
              </div>
              <div className="p-4 bg-gray-50 rounded">
                <h3 className="text-lg font-semibold mb-3">DC Innings: 153/7</h3>
                <div className="space-y-2">
                  <p className="text-sm">Run Rate: 7.52</p>
                  <p className="text-sm">Highest Scorer: RG Sharma (57 runs)</p>
                  <p className="text-sm">Most Sixes: RG Sharma (3)</p>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'batting' && (
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-3">RCB Batting</h3>
                <div className="h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={battingData.rcb}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="runs" fill="#0077B6" name="Runs" />
                      <Bar dataKey="balls" fill="#48CAE4" name="Balls" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold mb-3">DC Batting</h3>
                <div className="h-72">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={battingData.dc}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="runs" fill="#EF476F" name="Runs" />
                      <Bar dataKey="balls" fill="#FFD166" name="Balls" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'progression' && (
            <div>
              <h3 className="text-lg font-semibold mb-3">Over-by-Over Progression</h3>
              <div className="h-96">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={scoringProgressionData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="over" 
                      label={{ value: 'Overs', position: 'insideBottom', offset: -5 }}
                    />
                    <YAxis 
                      label={{ value: 'Runs', angle: -90, position: 'insideLeft' }}
                    />
                    <Tooltip />
                    <Legend />
                    <Line 
                      type="monotone" 
                      dataKey="RCB" 
                      stroke="#0077B6" 
                      strokeWidth={2} 
                      name="RCB (Batting First)"
                    />
                    <Line 
                      type="monotone" 
                      dataKey="DC" 
                      stroke="#EF476F" 
                      strokeWidth={2} 
                      name="DC (Chasing)"
                    />
                    <Line 
                      type="monotone" 
                      dataKey="target" 
                      stroke="#888888" 
                      strokeDasharray="5 5" 
                      name="Target (157)"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default MatchDashboard;