import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';
import { TrendingUp, Activity, AlertTriangle, Clock, Globe, Smartphone, MapPin } from 'lucide-react';
import { AppDispatch, RootState } from '../../ReduxToolkit/Store';
import { getAnalyticsData, getOperatorAnalyticsData } from '../../ReduxToolkit/Reducers/Change/AnalyticsSlice';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { convertToIST } from '../../Utils';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const AnalyticsCard = ({ title, value, icon: Icon }: any) => (
  <Card className="mb-3 shadow-sm">
    <CardBody className="d-flex justify-content-between align-items-center">
      <div>
        <h6 className="mb-0 text-muted">{title}</h6>
        <h3 className="mb-0">{value}</h3>
      </div>
      <div className="bg-primary rounded-circle p-3">
        <Icon size={24} color="white" />
      </div>
    </CardBody>
  </Card>
);

const EnhancedAnalytics = () => {
  const dispatch = useDispatch<AppDispatch>();
  const analyticsData = useSelector((state: RootState) => state.analytics.analytics);
  const apiAnalyticsData = useSelector((state: RootState) => state.analytics.api_analytics);
  const [activeTab, setActiveTab] = useState('1');
  console.log("apiAnalyticsData", apiAnalyticsData);
  const user = JSON.parse(localStorage.getItem("login-user")!)?.user;
  useEffect(() => {
    dispatch(getAnalyticsData());
    dispatch(getOperatorAnalyticsData(user?.id));
  }, [dispatch]);

  const analyticsCardData = analyticsData?.data?.map((item: any) => ({
    title: item.name,
    value: item.count,
    icon: TrendingUp // You can assign different icons based on the item name
  })) || [];

  // Mock data for charts - replace with actual data from your API
  const labels = apiAnalyticsData?.data?.api_calls?.map((item: any) => item.month) || [];
  const calls = apiAnalyticsData?.data?.api_calls?.map((item: any) => item.calls) || [];
  
  const apiUsageData = {
    labels: labels,
    datasets: [
      {
        label: 'API Calls',
        data: calls,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };

  const responseUsage = apiAnalyticsData?.data?.response_time_distribution?.map((item: any) => item?.range);
  const getRandomColor = () => {
    const letters = 'CDEF'; // Using only high values to ensure the colors are light
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 4)];
    }
    return color;
  };
  const apiResponseTimeData = {
    labels: responseUsage || [],
    datasets: [
      {
        label: 'Hits',
        data: apiAnalyticsData?.data?.response_time_distribution?.map((item: any) => item?.count),
        backgroundColor: apiAnalyticsData?.data?.response_time_distribution?.map(() => getRandomColor()),
        borderColor: apiAnalyticsData?.data?.response_time_distribution?.map(() => getRandomColor()),
        borderWidth: 1,
      },
    ],
  };


  const apiErrorLabels = apiAnalyticsData?.data?.api_errors && apiAnalyticsData?.data?.api_errors?.length>0 && apiAnalyticsData?.data?.api_errors?.map((item: any) => item?.total_errors) || [];
  const apiErrorData = {
    labels: apiErrorLabels || [],
    datasets: [
      {
        label: 'Error Count',
        data: apiErrorLabels && apiErrorLabels.length>0 && apiErrorLabels || [],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ]
  };

  return (
    <div className='page-body'>
      <Container fluid className="p-4 bg-light">
        {/* <h1 className="mb-4">Analytics Dashboard</h1> */}
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === '1' ? 'active' : ''}
              onClick={() => setActiveTab('1')}
            >
              API Usage
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '2' ? 'active' : ''}
              onClick={() => setActiveTab('2')}
            >
              Performance
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === '3' ? 'active' : ''}
              onClick={() => setActiveTab('3')}
            >
              Error Analysis
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row className="mt-3">
              <Col md={8}>
                <Card>
                  <CardBody>
                    <h4>API Usage Over Time</h4>
                    <Line data={apiUsageData} />
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <CardBody>
                    <h4>Recent API Calls</h4>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Endpoint</th>
                          <th>Time</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          apiAnalyticsData?.data?.recent_api_calls?.slice(0,3)?.map((item: any) => {
                            return (
                              <tr>
                                <td>{item?.endpoint}</td>
                                <td>{moment(item.created_at).fromNow()}</td>
                                <td><Badge color={item?.failed?"danger":"success"}>{item?.faild?"400":"200"}</Badge></td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row className="mt-3">
              <Col md={6}>
                <Card>
                  <CardBody>
                    <h4>API Response Time Distribution</h4>
                    <Pie data={apiResponseTimeData} />
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <h4>Top Performing Endpoints</h4>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Endpoint</th>
                          <th>Avg. Response Time</th>
                          <th>Calls/min</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          apiAnalyticsData?.data?.top_performing_apis?.map((item: any) => {
                            return (
                              <tr>
                                <td>{item?.endpoint}</td>
                                <td>{item?.avg_response_time}</td>
                                <td>{item?.calls_per_min}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row className="mt-3">
              <Col md={8}>
                <Card>
                  <CardBody>
                    <h4>API Errors by Type</h4>
                    <Bar data={apiErrorData} />
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <CardBody>
                    <h4>Recent Errors</h4>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Error</th>
                          <th>Endpoint</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          apiAnalyticsData?.data?.api_errors?.recent_errors.length>0?
                          apiAnalyticsData?.data?.api_errors?.recent_errors?.map((item: any) => {
                            return (
                              <tr>
                                <td><Badge color="danger">404</Badge></td>
                                <td>/api/unknown</td>
                                <td>2 min ago</td>
                              </tr>
                            )
                          }):"No error"
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        <Row className="mt-4">
          <Col md={6}>
            <Card>
              <CardBody>
                <h4>API Usage Details</h4>
                <p><Clock size={18} className="me-2" /> Last API Call: {convertToIST(apiAnalyticsData?.data?.api_usage_details?.last_api_call?.created_at)}</p>
                <p><Globe size={18} className="me-2" /> Domain: {apiAnalyticsData?.data?.api_usage_details?.domain}</p>
                <p><Smartphone size={18} className="me-2" /> Last Device: {apiAnalyticsData?.data?.api_usage_details?.last_device?.split(",")[0]}</p>
                <p><MapPin size={18} className="me-2" /> Last Location: {apiAnalyticsData?.data?.api_usage_details?.last_location}</p>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <h4>API Health</h4>
                <p><Activity size={18} className="me-2" /> Current Status: Operational</p>
                <p><AlertTriangle size={18} className="me-2" /> Active Alerts: None</p>
                <p>Uptime: 99.99% (last 30 days)</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {analyticsCardData.map((item: any, index: any) => (
            <Col key={index} md={3} className="mb-3">
              <AnalyticsCard title={item.title} value={item.value} icon={item.icon} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default EnhancedAnalytics;