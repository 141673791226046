import React, { useState } from 'react';
import {
  Container, Row, Col, Card, CardBody, CardHeader,
  Form, FormGroup, Label, Input, Button, Table,
  Badge, Alert, Nav, NavItem, NavLink, TabContent, TabPane,
  Switch
} from 'reactstrap';
import {
  Plus, Trash2, Bell, Settings, Users, Link,
  Mail, MessageSquare, Phone, Slack, Save,
  Edit2, AlertTriangle, Check, X
} from 'lucide-react';
import './ConfigurationPanel'

const ConfigurationPanel = () => {
  const [activeTab, setActiveTab] = useState('endpoints');
  // interface ConfigurationState {
  //   endpoints: Endpoint[];
  //   alertSettings: AlertSettings;
  //   notifications: NotificationSettings;
  //   teamMembers: TeamMember[];
  // }
  
  
  
  // const validateEndpoint = (endpoint: Endpoint) => {
  //   const errors: ValidationErrors = {};
  //   if (!endpoint.url) errors.url = 'URL is required';
  //   if (!endpoint.interval) errors.interval = 'Check interval is required';
  //   return errors;
  // };
  return (
    <div className='page-body'>
    <Container fluid className="p-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Configuration</h2>
        <Button color="success">
          <Save size={18} className="me-2" />
          Save Changes
        </Button>
      </div>

      <Row>
        <Col md={3}>
          <Nav vertical pills className="config-nav">
            <NavItem>
              <NavLink 
                className={activeTab === 'endpoints' ? 'active' : ''} 
                onClick={() => setActiveTab('endpoints')}
              >
                <Link size={18} className="me-2" />
                Endpoint Management
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink 
                className={activeTab === 'alerts' ? 'active' : ''} 
                onClick={() => setActiveTab('alerts')}
              >
                <AlertTriangle size={18} className="me-2" />
                Alert Settings
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink 
                className={activeTab === 'notifications' ? 'active' : ''} 
                onClick={() => setActiveTab('notifications')}
              >
                <Bell size={18} className="me-2" />
                Notification Preferences
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink 
                className={activeTab === 'team' ? 'active' : ''} 
                onClick={() => setActiveTab('team')}
              >
                <Users size={18} className="me-2" />
                Team Access Controls
              </NavLink>
            </NavItem>
          </Nav>
        </Col>

        <Col md={9}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="endpoints">
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Endpoint Management</h4>
                  <Button color="primary" size="sm">
                    <Plus size={18} className="me-2" />
                    Add Endpoint
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>Endpoint</th>
                        <th>Check Interval</th>
                        <th>Status</th>
                        <th>Last Check</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>/api/v1/scores</td>
                        <td>30s</td>
                        <td><Badge color="success">Active</Badge></td>
                        <td>2 mins ago</td>
                        <td>
                          <Button color="link" className="p-0 me-2">
                            <Edit2 size={16} />
                          </Button>
                          <Button color="link" className="p-0 text-danger">
                            <Trash2 size={16} />
                          </Button>
                        </td>
                      </tr>
                      {/* Add more endpoint rows */}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </TabPane>

            <TabPane tabId="alerts">
              <Card>
                <CardHeader>
                  <h4 className="mb-0">Alert Settings</h4>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h5 className="mb-3">Response Time Thresholds</h5>
                    <Row>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Warning Threshold (ms)</Label>
                          <Input type="number" defaultValue={1000} />
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup>
                          <Label>Critical Threshold (ms)</Label>
                          <Input type="number" defaultValue={2000} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <h5 className="mb-3 mt-4">Availability Alerts</h5>
                    <Row>
                      <Col md={12}>
                        <FormGroup check className="mb-2">
                          <Input type="checkbox" defaultChecked />
                          <Label check>
                            Alert on consecutive failures
                          </Label>
                        </FormGroup>
                        <FormGroup check className="mb-2">
                          <Input type="checkbox" defaultChecked />
                          <Label check>
                            Alert on SSL certificate expiration
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </TabPane>

            <TabPane tabId="notifications">
              <Card>
                <CardHeader>
                  <h4 className="mb-0">Notification Preferences</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <Card className="mb-3">
                        <CardBody>
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Mail size={20} className="me-2" />
                              Email Notifications
                            </div>
                            <div className="form-check form-switch">
                              <Input type="switch" defaultChecked />
                            </div>
                          </div>
                          <FormGroup>
                            <Input type="email" placeholder="Enter email addresses" />
                            <small className="text-muted">Separate multiple emails with commas</small>
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <Card className="mb-3">
                        <CardBody>
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Phone size={20} className="me-2" />
                              SMS Notifications
                            </div>
                            <div className="form-check form-switch">
                              <Input type="switch" />
                            </div>
                          </div>
                          <FormGroup>
                            <Input type="tel" placeholder="Enter phone numbers" />
                            <small className="text-muted">Separate multiple numbers with commas</small>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={6}>
                      <Card className="mb-3">
                        <CardBody>
                          <div className="d-flex justify-content-between align-items-center mb-3">
                            <div>
                              <Slack size={20} className="me-2" />
                              Slack Integration
                            </div>
                            <div className="form-check form-switch">
                              <Input type="switch" defaultChecked />
                            </div>
                          </div>
                          <FormGroup>
                            <Input type="text" placeholder="Webhook URL" />
                            <small className="text-muted">Enter your Slack webhook URL</small>
                          </FormGroup>
                        </CardBody>
                      </Card>

                      <Card className="mb-3">
                        <CardBody>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <MessageSquare size={20} className="me-2" />
                              Teams Integration
                            </div>
                            <div className="form-check form-switch">
                              <Input type="switch" />
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </TabPane>

            <TabPane tabId="team">
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Team Access Controls</h4>
                  <Button color="primary" size="sm">
                    <Plus size={18} className="me-2" />
                    Add Team Member
                  </Button>
                </CardHeader>
                <CardBody>
                  <Table hover responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>John Doe</td>
                        <td>john@example.com</td>
                        <td>Admin</td>
                        <td><Badge color="success">Active</Badge></td>
                        <td>
                          <Button color="link" className="p-0 me-2">
                            <Edit2 size={16} />
                          </Button>
                          <Button color="link" className="p-0 text-danger">
                            <Trash2 size={16} />
                          </Button>
                        </td>
                      </tr>
                      {/* Add more team member rows */}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
    </div>
  );
};

export default ConfigurationPanel;