import Error503Container from '../../../../Componant/OtherPages/Error/Error503/Error503'

const Error503 = () => {
  return (
    <div className='page-body'>
      <Error503Container />
    </div>
  )
}

export default Error503