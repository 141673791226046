import ContainerProject from "../../../Componant/Dashboard/ProjectDashboard/ProjectDashboard";

const ProjectDashboard = () => {
  return (
    <div className='page-body'>
      <ContainerProject />
    </div>
  );
};

export default ProjectDashboard;
