import { Provider, useDispatch, useSelector } from "react-redux";
import RouterData from "./Routes";
import { ToastContainer } from "react-toastify";
import Store, { AppDispatch, RootState } from "./ReduxToolkit/Store";
import { useEffect } from "react";
import { getAllUsers, sharedLogin } from "./ReduxToolkit/Reducers/Change/AuthSlice";
import { getSubscribe } from "./ReduxToolkit/Reducers/Change/Subscribe";
import { getLoggedUserProfile } from "./ReduxToolkit/Reducers/Change/ProfileSlice";
import "./App.css"
import { FRONTEND_MAIN_URL } from "./Utils";
import { useLocation, useNavigate } from "react-router-dom";

const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenFromURL: any = queryParams.get('token');
  console.log("tokenFromURL", tokenFromURL);
  const navigate = useNavigate();
  // if (!tokenFromURL) {
  //   window.location.href = `${FRONTEND_MAIN_URL}/login`;
  // }

  const sharedLoginFn = async () => {
    try {
      const res = await dispatch(sharedLogin(tokenFromURL)).unwrap();
      console.log("res---- login", res);
      const userJsonString = JSON.stringify({ user: res.data, token: res.token });
      localStorage.setItem("login", JSON.stringify(true));
      localStorage.setItem("login-user", userJsonString);
      navigate(`${process.env.PUBLIC_URL}/dashboard/home`);
      // Once sharedLoginFn completes, fetch referers and plans
      await fetchRefferer();
      await fetchPlans();
    } catch (error) {
      console.log("Error from sharedLoginFn", error);
    }
  };

  const fetchRefferer = async () => {
    await dispatch(getAllUsers());
  };

  const fetchPlans = async () => {
    await dispatch(getSubscribe());
  };

  useEffect(() => {
    // Call sharedLoginFn within useEffect
    sharedLoginFn();
  }, [dispatch]);
  
  return (
    <>
      <Provider store={Store} >
          <RouterData />
          <ToastContainer />
      </Provider>
    </>
  );
};

export default App;
