import { Table } from "reactstrap";
import {
  BillingAddress,
  Phone,
  ShippingAddress,
} from "../../../../../utils/Constant";
import { H4 } from "../../../../../AbstractElements";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../ReduxToolkit/Store";

const InvoiceTwoBilling = ({ data, from }: any) => {
  const profileData = useSelector((state: RootState) => state.profile.profile);
  return (
    <td style={{padding: '0'}}>
      <Table className="table-responsive" style={{ width: "100%" }} borderless>
        <tbody>
          <tr style={{ padding: "5px 0", display: "block" }}>
            <td style={{ width: "70%", minWidth: 304 }}>
              <span style={{ opacity: "0.8", fontSize: 12, fontWeight: 500 }}>
                <b>{"From"}</b>
              </span>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>Name:</b> */}
                Prosport
              </span>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>Company:</b> */}
                ProSportsAPI
              </span>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>Address:</b> */}
                133/A Kuber Complex Link Road, Andheri west
                Mumbai - 400053 Maharashtra, India
              </span>
              <H4
                style={{ fontWeight: 400, margin: "6px 0 3px 0", fontSize: 12 }}
              >
                {/* <b>Email:</b> */}
                support@prosportsapi.com
              </H4>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>{"Phone"} :</b>  */}
                +91 773 888 9594
              </span>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                <b>{"GST"} :</b> 27AAECS1234R1ZV
              </span>
            </td>
            <td style={{ minWidth: 280 }}>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                <b>{"To"}</b>
              </span>
              <span
                style={{
                  width: "54%",
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>Name:</b> */}
                {from === "ma"
                  ? `${data?.firstname} ${data?.lastname}`
                  : `${profileData?.data?.name} ${profileData?.data?.lastname}`}
                <span
                  style={{
                    display: "block",
                    lineHeight: "1.5",
                    opacity: "0.8",
                    fontSize: 12,
                    fontWeight: 400,
                  }}
                >
                  {/* <b>Company:</b> */}
                  {
                  from === "ma"
                    ? `${data?.company_name}`
                    : `${profileData?.data?.company_name}`
                  }
                  {/* {
                  from === "ma"?`${data?.address}`:
                  `${profileData?.data?.address}` 
                } */}
                </span>
                
              </span>
              <span
                style={{
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>Address: </b> */}
                {from === "ma"
                  ? `${data?.address}`
                  : `${profileData?.data?.address}`}
              </span>
              <span
                style={{
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
              {/* <b>Email:</b> */}
                {from === "ma" ? data?.email : profileData?.data?.email}
                </span>
              <span
                style={{
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                {/* <b>{"Phone"} :</b> */}
                {from === "ma"
                  ? `${data?.phone}`
                  : `${profileData?.data?.phone}`}
              </span>
              <span
                style={{
                  display: "block",
                  lineHeight: "1.5",
                  opacity: "0.8",
                  fontSize: 12,
                  fontWeight: 400,
                }}
              >
                <b>{"GST"} :</b>
                {from === "ma"
                  ? `${data?.phone}`
                  : `${profileData?.data?.gst}`}
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </td>
  );
};

export default InvoiceTwoBilling;
