import React, { useState } from 'react';
import { 
  Activity, AlertTriangle, Settings, LineChart, 
  Bell, Users, FileText, TrendingUp, Clock,
  CheckCircle, XCircle, AlertCircle, Database
} from 'lucide-react';
import {
  Container, Row, Col, Card, CardBody,
  Nav, NavItem, NavLink, TabContent, TabPane,
  Badge, Progress, Button
} from 'reactstrap';

const StatusIndicator = ({ status }) => {
  const colors = {
    operational: 'success',
    degraded: 'warning',
    down: 'danger'
  };
  return (
    <div className="d-flex align-items-center">
      <div className={`bg-${colors[status]} rounded-circle`} 
           style={{ width: 8, height: 8, marginRight: 8 }} />
      <span className="text-capitalize">{status}</span>
    </div>
  );
};

export default function MonitoringDashboard() {
  const [activeTab, setActiveTab] = useState('status');

  // Mock data for demonstration
  const statusData = {
    uptime: 99.98,
    responseTime: 234,
    activeEndpoints: 12,
    errorRate: 0.02
  };

  const endpoints = [
    { name: '/api/scores', status: 'operational', responseTime: 189 },
    { name: '/api/matches', status: 'operational', responseTime: 210 },
    { name: '/api/stats', status: 'degraded', responseTime: 450 }
  ];

  return (
    <div className="page-body">
      <Container fluid className="p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h2>API Monitor Dashboard</h2>
          <div>
            <Button color="primary" className="me-2">
              <Bell size={18} className="me-2" />
              Alerts
            </Button>
            <Button color="light">
              <Settings size={18} />
            </Button>
          </div>
        </div>

        <Nav tabs className="mb-4">
          <NavItem>
            <NavLink
              active={activeTab === 'status'}
              onClick={() => setActiveTab('status')}
            >
              <Activity size={18} className="me-2" />
              Status Dashboard
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === 'config'}
              onClick={() => setActiveTab('config')}
            >
              <Settings size={18} className="me-2" />
              Configuration
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              active={activeTab === 'reports'}
              onClick={() => setActiveTab('reports')}
            >
              <FileText size={18} className="me-2" />
              Reports
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="status">
            <Row>
              <Col md={3}>
                <Card className="mb-4">
                  <CardBody>
                    <h6 className="text-muted mb-2">Uptime</h6>
                    <h3 className="mb-0">{statusData.uptime}%</h3>
                    <Progress
                      value={statusData.uptime}
                      color="success"
                      className="mt-2"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="mb-4">
                  <CardBody>
                    <h6 className="text-muted mb-2">Avg Response Time</h6>
                    <h3 className="mb-0">{statusData.responseTime}ms</h3>
                    <Progress
                      value={75}
                      color="info"
                      className="mt-2"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="mb-4">
                  <CardBody>
                    <h6 className="text-muted mb-2">Active Endpoints</h6>
                    <h3 className="mb-0">{statusData.activeEndpoints}</h3>
                    <Progress
                      value={100}
                      color="primary"
                      className="mt-2"
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="mb-4">
                  <CardBody>
                    <h6 className="text-muted mb-2">Error Rate</h6>
                    <h3 className="mb-0">{statusData.errorRate}%</h3>
                    <Progress
                      value={statusData.errorRate * 100}
                      color="danger"
                      className="mt-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <Card className="mb-4">
                  <CardBody>
                    <h4>Response Time Trend</h4>
                    {/* Add your Line chart component here */}
                  </CardBody>
                </Card>
              </Col>
              <Col md={4}>
                <Card className="mb-4">
                  <CardBody>
                    <h4>Endpoint Status</h4>
                    {endpoints.map(endpoint => (
                      <div key={endpoint.name} className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <p className="mb-0">{endpoint.name}</p>
                          <small className="text-muted">{endpoint.responseTime}ms</small>
                        </div>
                        <StatusIndicator status={endpoint.status} />
                      </div>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="config">
            <Row>
              <Col md={6}>
                <Card className="mb-4">
                  <CardBody>
                    <h4>Endpoint Management</h4>
                    {/* Add endpoint management form */}
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-4">
                  <CardBody>
                    <h4>Alert Settings</h4>
                    {/* Add alert configuration form */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="reports">
            <Row>
              <Col md={12}>
                <Card className="mb-4">
                  <CardBody>
                    <h4>Usage Reports</h4>
                    {/* Add reports and analytics content */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
}