import ComingSoonWithBgImageContainer from '../../../../Componant/OtherPages/ComingSoon/ComingSoonWithBgImage/ComingSoonWithBgImage'

const ComingSoonWithBgImage = () => {
  return (
    <div className='page-body'>
      <ComingSoonWithBgImageContainer />
    </div>
  )
}

export default ComingSoonWithBgImage