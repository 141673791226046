import LoginSimpleContainer from '../../../../Componant/OtherPages/Authentication/LoginSimple/LoginSimple'

const LoginSimple = () => {
  return (
    <div className='page-body'>
      <LoginSimpleContainer />
    </div>
  )
}

export default LoginSimple